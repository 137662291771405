.App {
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  
}
.footer{
  position:fixed;
  bottom:0;
  clear:both;
  width:100%;
  height:50px;

}
.file_name{
  font-size: 9px;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.color-app-bg{
  background-color: #97deff;
}

.container-app-header{
  height:7%;
  margin: 0;

  background-color: #97deff;
  max-height: 7vh;
  min-height: 7vh;
  
}
.container-app-body{
  height: 70%;
  margin: 0; 
  max-height: 100%;
  min-height: 100%;
  
}
.container-tracking{
  height:100%;
  margin: 0;
  background-color: transparent;
  max-height: 70vh;
  min-height: 70vh;
  
}
.container-h-10{
  height: 20%;
  max-height: 20%;
  min-height: 20%;
}
.pointer{
  cursor: pointer;
}
.h-5{
  height: 5%;
  max-height: 5%;
  min-height: 5%;
}
.h-7{
  height: 7%;
  max-height: 7%;
  min-height: 7%;
}
.h-10{
  height: 10%;
  max-height: 10%;
  min-height: 10%;
}
.h-15{
  height: 15%;
  max-height: 15%;
  min-height: 15%;
}
.h-18{
  height: 18%;
  max-height: 18%;
  min-height: 18%;
}
.h-19{
  height: 19%;
  max-height: 19%;
  min-height: 19%;
}

.h-20{
  height: 20%;
  max-height: 20%;
  min-height: 20%;
}
.h-29{
  height: 29%;
  max-height: 29%;
  min-height: 29%;
}
.h-30{
  height: 30%;
  max-height: 30%;
  min-height: 30%;
}

.h-33{
  height: 33%;
  max-height: 33%;
  min-height: 33%;
}
.h-35{
  height: 35%;
  max-height: 35%;
  min-height: 35%;
}
.h-40{
  height: 40%;
  max-height: 40%;
  min-height: 40%;
}
.h-45{
  height: 45%;
  max-height: 45%;
  min-height: 45%;
}

.h-50{
  height: 50%;
  max-height: 50%;
  min-height: 50%;
}

.h-70{
  height: 70%;
  max-height: 70%;
  min-height: 70%;
}
.h-80{
  height: 80%;
  max-height: 80%;
  min-height: 80%;
}
.h-85{
  height: 85%;
  max-height: 85%;
  min-height: 85%;
}
.h-85-vh{
  height: 85vh;
  max-height: 85vh;
  min-height: 8vh;
}
.h-90{
  height: 90%;
  max-height: 90%;
  min-height: 90%;
}

.bitacora-container{
  font-size: 14px;
  color:#9c182f;
  font-weight: bold;
}

.img-w-100{
  width: 100%;
  max-width: 100%;

}
.img-h-100{
  height: 100%;
  max-height: 100%;
  width: auto;
  
}
.img-h-20{
  height: 20%;
  max-height: 80px;
  width: auto;
  
}

.img-h-100-40{
  height: 80%;
  max-height: 40px;
  width: auto;
  
}
.img-h-70-30{
  height: 70%;
  max-height: 30px;
  width: auto;
  
}
.container-w-80{
  width: 80%;
  max-width: 80%;
}
.container-w-30{
  width: 30%;
  max-width: 30%;
}

.container-category-name{
  
  font-size: 12px
}
.container-flow-tracking{
  height:80%;
  margin: 0;
  background-color: transparent;
  max-height: 35vh;
  min-height: 35vh;
  
}
.row{
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.col-no-pl{
  padding-left: 0;

}
.col-no-pr{
  padding-right: 0;
}
.col-no-px{
  padding-left: 0;
  padding-right: 0;
}
.col-no-py{
  padding-top: 0;
  padding-bottom: 0;
}
.col-no-p{
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.container-flow-icons-h{
  height:7vh;
  margin: 0;
  background-color: transparent;
  max-height: 7vh;
  min-height: 7vh;
}
.container-flow-icons-i{
  height:15vh;
  margin: 0;
  padding-left:10%;
  background-color: transparent;
  max-height: 15vh;
  min-height: 15vh;
}
.container-status-default-icons{
  height:80%;
  margin: 0;
  padding-left:4%;
  background-color: transparent;
  max-height: 80%;
  min-height: 80%;
}
.text-justify {
  text-align: justify;
}

.d-flex-no-p{
  padding-left: 0;
  padding-right: 0;
}
.font-size-detail{
  font-size: 0.85em;
}
.p{
  padding-bottom: 0;
  margin-bottom: 0;
}
.sizeText{
  
  font-size: 16px;
  color:black;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
